import React, { useEffect, useReducer } from "react";
import { CircularProgress } from "@mui/material";
import { Auth } from "aws-amplify";
import { initialState, reducer, AuthState, AuthAction } from "./authReducer";
import {
  getSignedInUser,
  initializeApp,
  refreshRoles,
  refreshSignUpStep,
} from "./authActions";
import Home from "../Home";
import SignupLayout from "./SignUp/SignUpLayout";
import LoginLayout from "./Login/LoginLayout";
import "./AuthStateApp.css";
import DiscoveryOnboarding from "../DiscoveryOnboarding/DiscoveryOnboarding";
import NavigationService from "../../utils/NavigationService";
import { trackEvent } from "../intercom/intercomProvider";
import {
  startGoogleDiscovery,
  startMicrosoftDiscovery,
} from "../../utils/DiscoveryService";
import { deleteDeviceToken } from "xfa-connect-sdk-js";

const AuthStateHandler: React.FC = () => {
  const [state, dispatch] = useReducer<React.Reducer<AuthState, AuthAction>>(
    reducer,
    initialState,
  );

  // Initialize app on mount
  useEffect(() => {
    initializeApp(dispatch, state);
  }, []);

  // Refresh signup step whenever relevant state changes
  useEffect(() => {
    refreshSignUpStep(dispatch, state);
  }, [
    state.loading.paymentLoading,
    state.loading.roleLoading,
    state.loading.tokenLoading,
    state.loading.signInLoading,
    state.signupStep,
  ]);

  // Specific callback functions
  const handleNextStep = () => {
    if (state.signupStep > 1) {
      handleRefreshRoles();
    }
    dispatch({ type: "SET_SIGNUP_STEP", payload: state.signupStep + 1 });
  };

  const onGoogleDiscovery = async () => {
    const roles = state.roles;
    if (
      !roles ||
      roles.length === 0 ||
      roles[0].organization.organization_id === undefined
    ) {
      return;
    }
    dispatch({ type: "SET_LOADING", payload: { urlLoading: true } });
    trackEvent("Selecting Google Integration");
    await startGoogleDiscovery(roles[0], true);
    dispatch({ type: "SET_SIGNUP_STEP", payload: 0 });
    dispatch({ type: "SET_AUTH_STATE", payload: "signedIn" });
    dispatch({ type: "SET_LOADING", payload: { urlLoading: false } });
  };

  const onMicrosoftDiscovery = async () => {
    const roles = state.roles;
    if (
      !roles ||
      roles.length === 0 ||
      roles[0].organization.organization_id === undefined
    ) {
      return;
    }
    dispatch({ type: "SET_LOADING", payload: { discoveryLoading: true } });
    await startMicrosoftDiscovery(roles[0], true);
    dispatch({ type: "SET_AUTH_STATE", payload: "signedIn" });
    dispatch({ type: "SET_LOADING", payload: { discoveryLoading: false } });
  };

  const onOtherIdentificationMethod = () => {
    NavigationService.navigateToNewApplication();
    dispatch({ type: "SET_SIGNUP_STEP", payload: 0 });
    dispatch({ type: "SET_AUTH_STATE", payload: "signedIn" });
  };

  const onNoSetup = () => {
    NavigationService.navigateToHome();
    dispatch({ type: "SET_SIGNUP_STEP", payload: 0 });
    dispatch({ type: "SET_AUTH_STATE", payload: "signedIn" });
  };

  const handlePreviousStep = () => {
    if (state.signupStep === 1) {
      Auth.signOut();
      dispatch({ type: "SET_COGNITO_USER", payload: undefined });
      dispatch({ type: "SET_SIGNED_IN_USER", payload: undefined });
    }
    dispatch({
      type: "SET_SIGNUP_STEP",
      payload: Math.max(0, state.signupStep - 1),
    });
  };

  const handleSwitchToLogin = () => {
    Auth.signOut();
    dispatch({ type: "SET_COGNITO_USER", payload: undefined });
    dispatch({ type: "SET_SIGNED_IN_USER", payload: undefined });
    dispatch({ type: "SET_AUTH_STATE", payload: "login" });
    localStorage.removeItem("signUp");
  };

  const handleSignIn = async (user: any) => {
    if (!user) {
      return;
    }
    dispatch({ type: "SET_COGNITO_USER", payload: user });
    dispatch({ type: "SET_SIGNED_IN_USER", payload: getSignedInUser(user) });
    localStorage.setItem("signUp", user.username);
    const emailVerified =
      user.attributes?.email_verified ||
      user.signInUserSession?.idToken?.payload?.email_verified ||
      false;
    dispatch({
      type: "SET_EMAIL_VERIFIED",
      payload: emailVerified,
    });
    await refreshRoles(dispatch, state);
  };

  const handleRefreshRoles = async () => {
    await refreshRoles(dispatch, state);
  };

  const isLoading =
    state.loading.roleLoading ||
    state.loading.paymentLoading ||
    state.loading.signInLoading ||
    state.loading.tokenLoading ||
    state.loading.discoveryLoading ||
    state.loading.urlLoading;

  if (isLoading) {
    return (
      <div className="loading-container">
        <CircularProgress />
      </div>
    );
  }

  return (
    <>
      {state.signupStep === 5 ? (
        <DiscoveryOnboarding
          onGoogleDiscovery={onGoogleDiscovery}
          onMicrosoftDiscovery={onMicrosoftDiscovery}
          onNoSetup={onNoSetup}
          onOtherIdentificationMethod={onOtherIdentificationMethod}
        />
      ) : state.authState === "signedIn" && state.roles ? (
        <Home
          user={state.signedInUser!}
          onSignOut={async (fixedEmail?: string, signup?: boolean) => {
            await Auth.signOut();
            dispatch({ type: "SET_COGNITO_USER", payload: undefined });
            dispatch({ type: "SET_SIGNED_IN_USER", payload: undefined });
            dispatch({
              type: "SET_AUTH_STATE",
              payload: signup && signup === true ? "signup" : "login",
            });
            dispatch({ type: "SET_ROLES", payload: undefined });
            localStorage.clear();
            deleteDeviceToken();
            if (fixedEmail) {
              dispatch({ type: "SET_FIXED_EMAIL", payload: fixedEmail });
            }
          }}
        />
      ) : state.authState === "signup" ||
        (state.signedInUser && !state.roles && state.authState !== "login") ||
        (state.fixedEmail !== undefined && state.authState !== "login") ||
        state.signupStep === 4 ? (
        <SignupLayout
          currentStep={state.signupStep}
          onNextStep={handleNextStep}
          onPreviousStep={handlePreviousStep}
          onSwitchToLogin={handleSwitchToLogin}
          onRefreshRoles={handleRefreshRoles}
          onBillingSkip={() =>
            dispatch({ type: "SET_SIGNUP_STEP", payload: 5 })
          }
          onSignIn={handleSignIn}
          fixedEmail={state.fixedEmail}
          role={state.roles?.find((role) => !role.blocked_access)}
        />
      ) : (
        state.authState === "login" && (
          <LoginLayout
            onSignIn={handleSignIn}
            onSwitchToSignup={() =>
              dispatch({ type: "SET_AUTH_STATE", payload: "signup" })
            }
            cognitoUser={state.cognitoUser}
            setCognitoUser={(user: any) =>
              dispatch({ type: "SET_COGNITO_USER", payload: user })
            }
          />
        )
      )}
    </>
  );
};

export default AuthStateHandler;
