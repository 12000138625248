import React from "react";
import { Trans, useTranslation } from "react-i18next";
import {
  Typography,
  CircularProgress,
  Divider,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  Alert,
} from "@mui/material";
import "./Overview.css";
import XFA_API, { Application, Device, Policies, Role } from "../API/XFA_API";
import OverviewEmptyState from "./OverviewEmptyState";
import GetStarted, { Task } from "./GetStarted";
import { trackEvent } from "../intercom/intercomProvider";
import Usage from "../Statistics/Usage";
import Health from "../Statistics/Health";
import OperatingSystem from "../Statistics/OperatingSystems";
import blurredBackground from "../../images/overlay_blur_trial.png";
import NavigationService from "../../utils/NavigationService";
import DropdownMenu from "../General/Dropdown/DropdownMenu";
import DiscoveryHighlights from "../Discovery/DiscoveryHighlights";
import { getRecentDevices } from "../Users/UserUtils";
import { useLocation, useParams } from "react-router-dom";
import { Buffer } from "buffer";
import { isValidEmail } from "../../utils";

interface StatisticsProps {
  role: Role;
  onSignOut: (fixedEmail?: string, signup?: boolean) => Promise<void>;
}

const Statistics: React.FC<StatisticsProps> = (props: StatisticsProps) => {
  const { t } = useTranslation();

  const [isBlocked] = React.useState<boolean>(props.role.blocked_access);
  const [applicationCount, setApplicationCount] = React.useState<number>(0);
  const [loading, setLoading] = React.useState(true);
  const [showEmptyState, setShowEmptyState] = React.useState(true);
  const [applications, setApplications] = React.useState<Application[]>([]);
  const [dashboardApplicationId, setDashboardApplicationId] = React.useState<
    string | undefined
  >(undefined);
  const [showGetStartedEmptyState, setShowGetStartedEmptyState] =
    React.useState(true);
  const [tasks, setTasks] = React.useState<Task[]>([]);
  const [policies, setPolicies] = React.useState<Policies[] | undefined>(
    undefined,
  );

  const params = useParams();
  const decode = (str: string): string =>
    Buffer.from(str, "base64").toString("binary");
  const [isPopupOpen, setIsPopupOpen] = React.useState(false);
  const [username, setUsername] = React.useState<string | undefined>(
    params.username ? decode(params.username) : undefined,
  );
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  React.useEffect(() => {
    if (username && isValidEmail(username)) {
      setIsPopupOpen(true);
    }
  }, [params.username]);

  const refreshIntegrations = React.useCallback(async () => {
    if (isBlocked) return;
    try {
      setLoading(true);
      const applications = await XFA_API.getApplications(
        props.role.organization.organization_id,
      );
      setApplications(applications);
      const dashboardApplication = applications.find(
        (app) => app.UniquePurpose === "DashboardDemo",
      );
      if (dashboardApplication) {
        const dashboardApplicationId = dashboardApplication.ApplicationID;
        if (dashboardApplicationId !== undefined) {
          setDashboardApplicationId(dashboardApplicationId);
        }
      }
      const filteredApplications = applications.filter(
        (app) => app.Type !== "Microsoft" && app.Type !== "Google",
      );
      setApplicationCount(filteredApplications.length);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      setApplicationError(error.message);
    }
  }, [props.role.organization.organization_id, t]);

  const [applicationError, setApplicationError] = React.useState<string>("");
  const [devicesError, setDevicesError] = React.useState<string>("");
  const [devices, setDevices] = React.useState<Device[] | undefined>(undefined);
  const [devicesLoading, setDevicesLoading] = React.useState<boolean>(true);
  const [selectedPolicy, setSelectedPolicy] = React.useState<
    Policies | undefined
  >(undefined);
  const [loadedPolicies, setLoadedPolicies] = React.useState<boolean>(false);

  const getDevices = React.useCallback(
    (role: Role, selectedPolicy: Policies | undefined) => {
      if (isBlocked) return;
      setDevicesLoading(true);
      XFA_API.getDevices(
        role.organization.organization_id,
        selectedPolicy
          ? selectedPolicy.policy_id
          : role.organization.default_policy_id,
      )
        .then((result) => {
          if (result) {
            setDevices(result);
            setDevicesLoading(false);
          } else {
            setDevicesError(t("devices.RetrieveError"));
            setDevicesLoading(false);
          }
        })
        .catch(() => {
          setDevicesError(t("devices.RetrieveError"));
          setDevicesLoading(false);
        });
    },
    [t],
  );

  const getPolicies = React.useCallback(() => {
    if (isBlocked) return;
    XFA_API.getPolicies(props.role.organization.organization_id)
      .then((policies: Policies[] | undefined) => {
        setPolicies(policies);
        setSelectedPolicy(
          policies?.find(
            (policy) =>
              policy.policy_id === props.role.organization.default_policy_id,
          ) ?? policies?.[0],
        );
        setLoadedPolicies(true);
      })
      .catch(() => {
        setApplicationError(t("applications.error"));
      });
  }, []);

  const hasDevices = devices !== undefined && devices?.length > 0;

  React.useEffect(() => {
    if (isBlocked) return;
    setDevicesLoading(true);
    getPolicies();
    refreshIntegrations();
  }, [props.role, refreshIntegrations]);

  React.useEffect(() => {
    if (isBlocked && !loadedPolicies) return;

    getDevices(props.role, selectedPolicy);
  }, [props.role, getDevices]);

  const isTaskCompleted = (
    taskId: number,
    tasks: Task[],
    checkLocalStorage: string | null,
    additionalCondition: boolean = false,
  ) => {
    return (
      checkLocalStorage === "true" ||
      tasks[taskId - 1].completed ||
      additionalCondition
    );
  };

  React.useEffect(() => {
    if (isBlocked) return;
    setLoading(true);
    const savedShowEmptyState = localStorage.getItem("showEmptyState");
    const savedShowGetStartedEmptyState = localStorage.getItem(
      "showGetStartedEmptyState",
    );

    if (savedShowEmptyState !== null) {
      setShowEmptyState(JSON.parse(savedShowEmptyState));
    }

    if (savedShowGetStartedEmptyState !== null) {
      setShowGetStartedEmptyState(JSON.parse(savedShowGetStartedEmptyState));
    }

    const tasks = [
      {
        id: 1,
        text: t("overview.emptyState.step1"),
        completed: false,
        onClick: () => {
          let currentUrl = window.location.origin + "/signup?step=5";
          NavigationService.navigateToExternal(currentUrl);
          trackEvent("Started " + t("overview.emptyState.step1"));
        },
      },
      {
        id: 2,
        text: t("overview.emptyState.step2"),
        completed: false,
        onClick: () => {
          NavigationService.navigateToDevices();
          trackEvent("Started " + t("overview.emptyState.step2"));
        },
      },
      {
        id: 3,
        text: t("overview.emptyState.step3"),
        completed: false,
        onClick: () => {
          const currentUrl = window.location.href;
          const redirectUrl = `https://web.xfa.tech/token?redirect_url=${encodeURIComponent(currentUrl + "?taskCompleted=true")}&email=${props.role.email}&application_id=NONE`;
          NavigationService.navigateToExternal(redirectUrl);
          trackEvent("Started " + t("overview.emptyState.step3"));
        },
      },
      {
        id: 4,
        text: t("overview.emptyState.step4"),
        completed: false,
        onClick: () => {
          NavigationService.navigateToAwarenessOverview("showLearnMore=true");
          trackEvent("Started " + t("overview.emptyState.step4"));
        },
        disabled: !hasDevices,
        tooltip: t("overview.emptyState.step4NoDevices"),
      },
      {
        id: 5,
        text: t("overview.emptyState.step5"),
        completed: false,
        onClick: () => {
          NavigationService.navigateToPolicy();
          trackEvent("Started " + t("overview.emptyState.step5"));
        },
      },
      {
        id: 6,
        text: t("overview.emptyState.step6"),
        completed: false,
        onClick: () => {
          NavigationService.navigateToNewApplication();
          trackEvent("Started " + t("overview.emptyState.step6"));
        },
      },
    ];

    var updatedTasks = tasks;
    const savedTasks = localStorage.getItem("tasks");
    if (savedTasks) {
      const parsedTasks = JSON.parse(savedTasks);
      updatedTasks = tasks.map((task, index) => ({
        ...task,
        completed: parsedTasks[index]?.completed || task.completed,
      }));
    }

    const filteredApplications = applications.filter(
      (app) => app.Type === "Microsoft" || app.Type === "Google",
    );

    updatedTasks = updatedTasks.map((task) => {
      switch (task.id) {
        case 1:
          return {
            ...task,
            completed: isTaskCompleted(
              1,
              updatedTasks,
              null,
              filteredApplications.length > 0,
            ),
          };
        case 2:
          return {
            ...task,
            completed: isTaskCompleted(
              2,
              updatedTasks,
              localStorage.getItem("devices"),
              hasDevices,
            ),
          };
        case 3:
          return {
            ...task,
            completed: isTaskCompleted(
              3,
              updatedTasks,
              new URLSearchParams(window.location.search).get("taskCompleted"),
            ),
          };
        case 4:
          return {
            ...task,
            completed: isTaskCompleted(
              4,
              updatedTasks,
              localStorage.getItem("verifyMail"),
            ),
          };
        case 5:
          return {
            ...task,
            completed: isTaskCompleted(
              5,
              updatedTasks,
              null,
              policies && policies.length > 0,
            ),
          };
        case 6:
          return {
            ...task,
            completed: isTaskCompleted(
              6,
              updatedTasks,
              null,
              applicationCount > 1,
            ),
          };
        default:
          return task;
      }
    });
    if (dashboardApplicationId !== undefined) {
      updatedTasks = updatedTasks.map((task) =>
        task.id === 3
          ? {
              ...task,
              onClick: () => {
                const url = new URL(process.env.REACT_APP_TOKEN_ENDPOINT!);
                url.searchParams.set(
                  "redirect_url",
                  window.location.href + "?taskCompleted=true",
                );
                url.searchParams.set("email", props.role.email);
                url.searchParams.set("application_id", dashboardApplicationId);
                NavigationService.navigateToExternal(url.toString());
                trackEvent("Started " + t("overview.emptyState.step3"));
              },
            }
          : task,
      );
    }

    localStorage.setItem(
      "tasks",
      JSON.stringify(updatedTasks.map(({ completed }) => ({ completed }))),
    );
    setTasks(updatedTasks);
    setLoading(false);
  }, [applicationCount, hasDevices]);

  React.useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1240 && showEmptyState) {
        handleDismiss();
      }
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [showEmptyState]);

  const handleDismiss = () => {
    setShowEmptyState(false);
    localStorage.setItem("showEmptyState", JSON.stringify(false));
  };

  const handleGetStartedDismiss = () => {
    setShowGetStartedEmptyState(false);
    localStorage.setItem("showGetStartedEmptyState", JSON.stringify(false));
  };

  if (isBlocked) {
    return (
      <>
        <Typography variant="pagetitle">{t("navigation.overview")}</Typography>
        <Divider style={{ marginTop: 16 }} />
        <div style={{ textAlign: "center", marginTop: 50 }}>
          <img src={blurredBackground} alt="blurred overview page" />
        </div>
        <Dialog
          sx={{
            "& .MuiDialog-paper": {
              width: "400px",
              padding: "24px",
              borderRadius: "12px",
              textAlign: "center",
              border: "1px solid #D9DCE0",
            },
          }}
          maxWidth="xs"
          open={true}
          hideBackdrop
          style={{
            height: "fit-content",
            width: "fit-content",
            top: "25%",
            left: "40%",
          }}
        >
          <DialogTitle
            style={{
              fontWeight: 600,
              fontSize: "18px",
              padding: 0,
              lineHeight: "28px",
              color: "#101828",
            }}
          >
            {t("overview.trialEnded.popupTitle")}
          </DialogTitle>
          <DialogContent
            style={{
              fontWeight: 400,
              fontSize: "14px",
              padding: 0,
              lineHeight: "20px",
              marginTop: "7px",
              color: "#475467",
            }}
          >
            {t("overview.trialEnded.popupContent")}
          </DialogContent>
          <DialogActions
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              marginTop: "32px",
              padding: 0,
              gap: "12px",
            }}
          >
            <Button
              style={{
                display: "flex",
                flexGrow: 1,
                height: "40px",
                fontSize: "14px",
                fontWeight: 600,
                border: "1px solid #E2E4E8",
                borderRadius: "6px",
              }}
              variant="outlined"
              autoFocus
              onClick={() => {
                const salesMessage = t("overview.trialEnded.salesMessage");
                window.Intercom("showNewMessage", salesMessage);
              }}
            >
              {t("overview.trialEnded.contactSales")}
            </Button>
            <Button
              style={{
                display: "flex",
                flexGrow: 1,
                height: "40px",
                fontSize: "14px",
                fontWeight: 600,
                borderRadius: "6px",
                margin: 0,
              }}
              variant="contained"
              onClick={() => {
                NavigationService.navigateToBilling();
              }}
            >
              {t("overview.trialEnded.updateBilling")}
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }

  if (loading || devicesLoading) {
    return (
      <div>
        <Typography variant="pagetitle">{t("navigation.overview")}</Typography>
        <Divider style={{ marginTop: 16 }} />
        <div style={{ textAlign: "center", marginTop: 50 }}>
          <CircularProgress size={30} />
        </div>
      </div>
    );
  }

  return (
    <div>
      <div style={{ display: "flex" }}>
        <div className="overview-header">
          <Typography variant="pagetitle">
            {t("navigation.overview")}
          </Typography>
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              width: "100%",
            }}
          >
            {policies && policies.length > 0 && (
              <DropdownMenu
                mode="select"
                defaultSelected={selectedPolicy?.name ?? "Select policy"}
                actions={policies.map((policy) => ({
                  label: (policy && policy.name) ?? "policy",
                  icon: null,
                  onClick: () => {
                    setSelectedPolicy(policy);
                    getDevices(props.role, policy);
                  },
                }))}
              />
            )}
            {!showEmptyState && !showGetStartedEmptyState && (
              <Button
                variant="contained"
                onClick={() => {
                  setShowEmptyState(true);
                  localStorage.setItem("showEmptyState", JSON.stringify(true));
                  setShowGetStartedEmptyState(true);
                  localStorage.setItem(
                    "showGetStartedEmptyState",
                    JSON.stringify(true),
                  );
                }}
                style={{ marginLeft: "16px" }}
              >
                {t("overview.emptyState.getStartedTitle")}
              </Button>
            )}
          </div>
        </div>
      </div>
      <Divider style={{ marginTop: 16 }} />
      {applicationError && (
        <div style={{ marginBottom: 8, marginTop: 16 }}>
          <Alert severity="error">{applicationError}</Alert>
        </div>
      )}
      {devicesError && (
        <div style={{ marginBottom: 8, marginTop: 16 }}>
          <Alert severity="error">{devicesError}</Alert>
        </div>
      )}
      {showEmptyState && (
        <OverviewEmptyState
          tasks={tasks}
          onClick={() => {}}
          onDismiss={handleDismiss}
          dismissable={hasDevices}
        />
      )}
      <div className="statistics">
        <div className="overview">
          {!showEmptyState && showGetStartedEmptyState && (
            <div className="get-started-overview-container">
              <GetStarted
                tasks={tasks}
                dismissable={true}
                onDismiss={handleGetStartedDismiss}
              />
            </div>
          )}
          {devices && devices?.length > 0 && (
            <>
              <Usage role={props.role} />
              <Health devices={devices} selectedPolicy={selectedPolicy} />
              <OperatingSystem devices={devices} />
            </>
          )}
        </div>
      </div>
      {policies &&
        policies.length > 0 &&
        devices &&
        devices.length > 0 &&
        selectedPolicy && (
          <DiscoveryHighlights
            role={props.role}
            devices={getRecentDevices(devices)}
            selectedPolicy={selectedPolicy}
          />
        )}
      <Dialog
        open={isPopupOpen}
        onClose={() => setIsPopupOpen(false)}
        aria-labelledby="username-popup-title"
      >
        <DialogTitle
          id="username-popup-title"
          style={{
            fontWeight: 600,
            fontSize: "18px",
            lineHeight: "28px",
            color: "#101828",
          }}
        >
          <Trans
            i18nKey="overview.invitation.popupTitle"
            values={{ email: username }}
            components={{ strong: <strong /> }}
          />
        </DialogTitle>
        <DialogContent>
          <Typography>
            <Trans
              i18nKey="overview.invitation.popupContent"
              values={{ email: props.role.email }}
              components={{ strong: <strong /> }}
            />
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsPopupOpen(false)} color="primary">
            {t("overview.invitation.cancel")}
          </Button>
          <Button
            onClick={async () => {
              const isSignup = searchParams.get("isSignup");
              await props.onSignOut(username, isSignup === "true");
              const firstPartParam =
                isSignup === "true" ? "/signup/" : "/signin/";
              NavigationService.navigateTo(firstPartParam + params.username);
              setIsPopupOpen(false);
            }}
            color="primary"
          >
            {t("overview.invitation.logout")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Statistics;
