import React from "react";
import { Chip, Tooltip } from "@mui/material";
import { UserRisk, DeviceRisk } from "./UserUtils";
import { useTranslation } from "react-i18next";
import { RiskIcons, SmallRiskIcons } from "./RiskIcons";

const StatusChip = ({
  status,
  small = false,
}: {
  status: UserRisk | DeviceRisk;
  small?: boolean;
}) => {
  const { t } = useTranslation();

  const statusStyles = {
    safe: {
      backgroundColor: "#D1FAE5",
      color: "#065F46",
      icon: small ? SmallRiskIcons.safe : RiskIcons.safe,
      label: t("Users.safe"),
      tooltip: "",
    },
    unsafe: {
      backgroundColor: "#FECACA",
      color: "#991B1B",
      icon: small ? SmallRiskIcons.unsafe : RiskIcons.unsafe,
      label: t("Users.unsafe"),
      tooltip: "",
    },
    unknown: {
      backgroundColor: "#FFF7ED",
      color: "#92400E",
      icon: small ? SmallRiskIcons.unknownColored : RiskIcons.unknownColored,
      label: t("Users.unknown"),
      tooltip: "",
    },
    unsupported: {
      backgroundColor: "transparent",
      color: "transparent",
      icon: <></>,
      label: "",
      tooltip: "",
    },
  };

  const style = statusStyles[status];
  return small ? (
    <Tooltip title={style.tooltip} placement="top" arrow>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: style.backgroundColor,
          color: style.color,
          borderRadius: "50%",
          width: "24px",
          height: "24px",
          margin: 0,
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        {style.icon}
      </div>
    </Tooltip>
  ) : (
    <Tooltip title={style.tooltip} placement="top" arrow>
      <Chip
        icon={style.icon}
        label={style.label}
        style={{
          backgroundColor: style.backgroundColor,
          color: style.color,
          fontWeight: 600,
          height: "24px",
          fontSize: "14px",
          marginTop: "4px",
        }}
      />
    </Tooltip>
  );
};

export default StatusChip;
