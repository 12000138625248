import XFA_API, {
  Application,
  ApplicationCreationRequest,
  OAuthConfiguration,
  Policies,
  Role,
  SAMLConfiguration,
} from "../../API/XFA_API";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  FormControl,
  CircularProgress,
  Divider,
  TextField,
  Typography,
  Alert,
} from "@mui/material";
import SelectionPage from "./New/SelectionPage";
import PolicyPage from "./PolicyPage";
import IDPPage from "./IDPPage";
import SPPage from "./New/SPPage";
import OAuth2Page from "./OAuth/OAuth2Page";
import { useParams } from "react-router-dom";
import MicrosoftEAMPage from "./Microsoft/MicrosoftEAMPage";
import OktaPage from "./Okta/OktaPage";
import OneloginPage from "./OneLogin/OneloginPage";
import GooglePage from "./Google/GooglePage";
import MicrosoftPage from "./Microsoft/MicrosoftPage";
import BooleanSetting from "./BooleanSetting";
import MFAPage from "./MFAPage";
import NavigationService from "../../../utils/NavigationService";
import BreadCrumbs from "../../General/BreadCrumbs";
import DropdownSetting from "./DropdownSettings";
import FilteredComponent from "./FilteredComponent";
import AddIcon from "@mui/icons-material/Add";

interface NewOrEditApplicationProps {
  role: Role;
  organizationId: string;
}

const NewOrEditApplication: React.FC<NewOrEditApplicationProps> = (
  props: NewOrEditApplicationProps,
) => {
  const { t } = useTranslation();
  const { applicationId } = useParams();
  const [creating, setCreating] = useState(applicationId ? false : true);

  const [loading, setLoading] = useState(true);
  const [application, setApplication] = useState<Application>();
  const [useCustomPolicy, setUseCustomPolicy] = useState<boolean>(
    application?.PolicyID === undefined,
  );
  const [policies, setPolicies] = useState<Policies[]>([]);
  const [selectedPolicy, setSelectedPolicy] = useState<Policies>();
  const [isDiscovery, setIsDiscovery] = useState(false);
  const [recentlyCreated, setRecentlyCreated] = useState(false);

  const [creatingOrUpdating, setCreatingOrUpdating] = useState(false);
  const breadcrumbLinks = [
    {
      label: window.location.href.includes("discovery")
        ? t("navigation.discovery")
        : t("navigation.applications"),
      href: window.location.href.includes("discovery")
        ? "/discovery"
        : "/applications",
    },
    {
      label: application?.Name
        ? t("applications.edit.title")
        : t("applications.createDialog.title"),
      href: applicationId ? `/applications/${applicationId}` : "#",
    },
  ];

  const [pageKey, setPageKey] = useState("select");
  const [sp, setSp] = useState("custom");

  const setOAuthConfiguration = async (oauthConfig: OAuthConfiguration) => {
    setApplication({
      ...(application || ({} as Application)),
      OAuthConfiguration: oauthConfig,
    });
  };

  const saveSAMLConfig = async (samlConfig: SAMLConfiguration) => {
    setApplication({
      ...(application || ({} as Application)),
      SAMLConfiguration: samlConfig,
    });
  };

  const [error, setError] = useState<string | undefined>(undefined);
  // Fetch application and policies
  React.useEffect(() => {
    const fetchPoliciesAndApplication = async () => {
      setLoading(true);

      try {
        const fetchedPolicies: Policies[] = await XFA_API.getPolicies(
          props.organizationId,
        );
        setPolicies(fetchedPolicies);

        if (applicationId) {
          const fetchedApplications = await XFA_API.getApplications(
            props.organizationId,
          );
          const app = fetchedApplications.find(
            (app) => app.ApplicationID === applicationId,
          );
          if (app === undefined) {
            NavigationService.navigateToApplications();
            return;
          }
          setApplication(app);

          if (app.Type === "Microsoft" || app.Type === "Google") {
            setIsDiscovery(true);
          } else {
            const selPolicy = fetchedPolicies.find(
              (policy) => policy.policy_id === app?.PolicyID,
            );
            setSelectedPolicy(selPolicy);
            setUseCustomPolicy(
              app?.PolicyID === undefined && fetchedPolicies.length === 0,
            );
          }
        }
      } catch (e) {
        setError(t("applications.loadError"));
      }

      setLoading(false);
    };

    fetchPoliciesAndApplication();
  }, [applicationId, props.organizationId, t]);

  const createApplication = async (application: ApplicationCreationRequest) => {
    setCreatingOrUpdating(true);
    if (policies && policies.length > 0) {
      application.PolicyID = policies[0].policy_id;
    }
    application = await XFA_API.createApplication(
      props.organizationId,
      application,
    )
      .then((respApplication) => {
        setRecentlyCreated(true);
        setUseCustomPolicy(false);
        setCreatingOrUpdating(false);
        if (policies && policies.length > 0) {
          respApplication.PolicyID = policies[0].policy_id;
        }
        setApplication(respApplication);
        const selPolicy = policies.find(
          (policy) => policy.policy_id === respApplication?.PolicyID,
        );
        setSelectedPolicy(selPolicy);
        return respApplication;
      })
      .catch((error) => {
        setCreatingOrUpdating(false);
        console.log(error);
        setError(t("applications.creationError"));
      });
    return application;
  };

  const saveApplication = async (application: Application) => {
    setCreatingOrUpdating(true);
    try {
      const respApplication = await XFA_API.updateApplication(application);
      setApplication(respApplication);
      const selPolicy = policies.find(
        (policy) => policy.policy_id === respApplication?.PolicyID,
      );
      setSelectedPolicy(selPolicy);
    } catch (error) {
      setError(t("applications.creationError"));
    } finally {
      setCreatingOrUpdating(false);
    }
  };

  const saveAndExitApplication = async () => {
    if (application) {
      await saveApplication(application);
      closeApplication(true);
    }
  };

  const closeApplication = (forceSave?: boolean | undefined) => {
    if (recentlyCreated && application && !forceSave) {
      XFA_API.deleteApplication(application);
    }
    if (window.location.pathname.includes("/discovery")) {
      NavigationService.navigateToDiscoveryOverview();
    } else if (window.location.pathname.includes("/applications")) {
      NavigationService.navigateToApplications();
    } else {
      NavigationService.navigateToHome();
    }
  };

  const continueCreatingApplication = React.useCallback(async () => {
    if (!application) {
      return;
    }
    if (policies && policies.length > 0) {
      application.PolicyID = policies[0].policy_id;
      setUseCustomPolicy(false);
    }
    if (sp === "custom") {
      //create the application
      await createApplication(application);
      //end creation (for full overview)
      setCreating(false);
    } else if (sp === "saml") {
      if (pageKey === "idp") {
        if (!application.SAMLConfiguration) {
          application.SAMLConfiguration = {} as SAMLConfiguration;
        }
        application.SAMLConfiguration.Enabled = true;
        await createApplication(application);
        setPageKey("sp");
      } else if (pageKey === "sp") {
        //end creation (for full overview)
        await saveApplication(application);
        setCreating(false);
      }
    } else {
      setCreating(false);
    }
  }, [application, createApplication, pageKey, saveApplication, sp]);

  return (
    <>
      <BreadCrumbs links={breadcrumbLinks} />
      <div style={{ display: "flex" }}>
        <Typography variant="pagetitle" component="h2">
          {!creating
            ? t("applications.edit.title")
            : pageKey === "microsoft"
              ? t("applications.createDialog.titleMicrosoft")
              : pageKey === "okta"
                ? t("applications.createDialog.titleOkta")
                : pageKey === "onelogin"
                  ? t("applications.createDialog.titleOnelogin")
                  : pageKey === "google"
                    ? t("applications.createDialog.titleGoogle")
                    : t("applications.createDialog.title")}
        </Typography>
        <div style={{ marginLeft: "auto" }}>
          <Button autoFocus onClick={() => closeApplication(false)}>
            {t("Users.delete.cancel")}
          </Button>
          {(!creating || pageKey !== "select") && (
            <Button
              onClick={() => {
                !creating
                  ? saveAndExitApplication()
                  : continueCreatingApplication();
              }}
              disabled={creatingOrUpdating}
              variant="contained"
            >
              {creatingOrUpdating && (
                <CircularProgress size="20px" style={{ marginRight: "10px" }} />
              )}
              {!creating
                ? t("applications.edit.save")
                : t("applications.createDialog.next")}
            </Button>
          )}
        </div>
      </div>
      <Divider style={{ marginTop: 16 }} />
      <Alert severity="error" style={{ display: error ? "flex" : "none" }}>
        {error}
      </Alert>
      {loading ? (
        <div className="w-full flex justify-center items-center">
          <CircularProgress />
        </div>
      ) : !creating ? (
        <>
          <FormControl className="column">
            {application?.UniquePurpose == "DashboardDemo" && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 8,
                }}
              >
                <div className="section" style={{ marginTop: 16 }}>
                  <BooleanSetting
                    label={t("applications.DashboardDemoLabel")}
                    explanation={t("applications.DashboardDemoExplanation")}
                    value={application?.Enabled || false}
                    onChange={(value) =>
                      setApplication({
                        ...(application || ({} as Application)),
                        Enabled: value,
                      })
                    }
                  />
                </div>
              </div>
            )}
            <Typography
              variant="formlabel"
              style={{ marginBottom: 4, marginTop: 16 }}
            >
              {t("applications.createDialog.name")}
            </Typography>
            <TextField
              required
              id="name"
              value={application?.Name}
              disabled={
                application?.UniquePurpose !== undefined || creatingOrUpdating
              }
              placeholder={t("applications.createDialog.namePlaceholder")}
              onChange={(event) => {
                if (!application) {
                  return;
                }
                setApplication({
                  ...application,
                  Name: event.target.value,
                });
              }}
              style={{
                marginBottom: 16,
              }}
            />
          </FormControl>
          <div style={{ display: "flex", flexWrap: "wrap", gap: 8 }}>
            <div
              className="column"
              style={{ display: "flex", flexDirection: "column", gap: 8 }}
            >
              {application?.Type === "MicrosoftEAM" && (
                <MicrosoftEAMPage
                  creating={false}
                  applicationId={application.ApplicationID}
                  application={application}
                  setApplication={setApplication}
                  vertical={true}
                />
              )}
              {application?.Type === "Okta" && (
                <OktaPage
                  creating={false}
                  application={application}
                  setApplication={setApplication}
                  vertical={true}
                />
              )}
              {application?.Type === "Google" && (
                <GooglePage
                  creating={false}
                  application={application}
                  setApplication={setApplication}
                  vertical={true}
                />
              )}
              {application?.Type === "Microsoft" && (
                <MicrosoftPage
                  creating={false}
                  application={application}
                  setApplication={setApplication}
                  vertical={true}
                />
              )}
              {application?.Type === "Onelogin" && (
                <OneloginPage
                  creating={false}
                  application={application}
                  setApplication={setApplication}
                  vertical={true}
                />
              )}
              {application?.Type === undefined && (
                <>
                  {application?.OAuthConfiguration && (
                    <OAuth2Page
                      creating={false}
                      applicationId={application.ApplicationID}
                      oauthConfiguration={application.OAuthConfiguration}
                      setOAuthConfiguration={setOAuthConfiguration}
                    />
                  )}
                  {application?.SAMLConfiguration && (
                    <IDPPage
                      creating={false}
                      samlConfig={application.SAMLConfiguration}
                      setSAMLConfig={saveSAMLConfig}
                    />
                  )}
                  {application?.SAMLConfiguration && (
                    <>
                      <SPPage
                        creating={false}
                        application={application}
                        setApplication={setApplication}
                        sp={sp}
                      />
                    </>
                  )}
                </>
              )}
              {!application?.OAuthConfiguration &&
                !["Google", "Microsoft"].includes(application?.Type ?? "") &&
                application && (
                  <MFAPage
                    vertical={true}
                    application={application}
                    setApplication={setApplication}
                  />
                )}
            </div>
            <div className="column">
              {!isDiscovery && (
                <div className="section" style={{ marginBottom: 16 }}>
                  <div
                    style={{
                      marginBottom: 24,
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>
                      <Typography variant="formsectiontitle">
                        {t("policies.policy")}
                      </Typography>
                      <Typography variant="instructions">
                        {t("policies.integrationLink")}
                      </Typography>
                    </div>
                  </div>
                  {policies && policies.length > 0 && (
                    <>
                      <DropdownSetting
                        label={t("policies.companyPolicy")}
                        explanation={t("policies.companyPolicyDescription")}
                        value={{
                          label:
                            selectedPolicy?.name ||
                            (policies &&
                              policies.length > 0 &&
                              policies[0].name) ||
                            t("policies.select"),
                          value:
                            selectedPolicy?.policy_id ||
                            (policies &&
                              policies.length > 0 &&
                              policies[0].policy_id) ||
                            "",
                        }}
                        actions={policies.map((policy) => ({
                          label: policy.name || "Unnamed Policy",
                          value: policy.policy_id,
                          onClick: (policyId) => {
                            setApplication({
                              ...application!,
                              PolicyID: policyId,
                            });
                            const selPolicy = policies.find(
                              (policy) => policy.policy_id === policyId,
                            );
                            setSelectedPolicy(selPolicy);
                          },
                          icon: null,
                        }))}
                        disabled={useCustomPolicy}
                      />
                      {useCustomPolicy && (
                        <div style={{ marginTop: 16 }}>
                          <BooleanSetting
                            label={t("policies.customPolicy")}
                            explanation={t("policies.customPolicyDescription")}
                            value={useCustomPolicy}
                            onChange={(value) => {
                              setUseCustomPolicy(value);
                              if (value) {
                                setApplication({
                                  ...(application || ({} as Application)),
                                  PolicyID: undefined,
                                });
                              } else {
                                setApplication({
                                  ...(application || ({} as Application)),
                                  PolicyID:
                                    policies && policies.length > 0
                                      ? policies[0].policy_id
                                      : "",
                                });
                              }
                            }}
                          />
                        </div>
                      )}
                    </>
                  )}
                  {policies && policies.length === 0 && (
                    <>
                      <Button
                        variant="outlined"
                        startIcon={<AddIcon />}
                        color="primary"
                        onClick={() => {
                          saveApplication(application!);
                          NavigationService.navigateToPolicies();
                        }}
                        disabled={loading}
                        style={{
                          marginLeft: 0,
                          width: "100%",
                          borderColor: "#E2E4E8",
                        }}
                      >
                        {t("policies.createPolicy.title")}
                      </Button>
                    </>
                  )}
                  {!useCustomPolicy && <div></div>}
                </div>
              )}
              {!["Google", "Microsoft"].includes(application?.Type ?? "") &&
                !isDiscovery &&
                useCustomPolicy &&
                !recentlyCreated &&
                application && (
                  <PolicyPage
                    application={application}
                    setApplication={setApplication}
                    organizationId={props.organizationId}
                    creatingOrUpdating={creatingOrUpdating}
                    sp={sp}
                    role={props.role}
                  />
                )}
              {!["Google", "Microsoft"].includes(application?.Type ?? "") &&
                application && (
                  <FilteredComponent
                    application={application}
                    setApplication={setApplication}
                    creatingOrUpdating={creatingOrUpdating}
                  />
                )}
            </div>
          </div>
        </>
      ) : (
        <>
          <div style={{ marginTop: 16 }} />
          {pageKey === "select" && (
            <SelectionPage
              role={props.role}
              setPage={setPageKey}
              setSp={setSp}
              setCreating={setCreating}
              createApplication={createApplication}
            />
          )}
          {pageKey === "loading" && (
            <div className="w-full flex justify-center items-center">
              <CircularProgress />
            </div>
          )}
          {pageKey === "idp" && (
            <IDPPage
              creating={true}
              samlConfig={application?.SAMLConfiguration}
              setSAMLConfig={saveSAMLConfig}
            />
          )}
          {pageKey === "sp" && application && (
            <>
              <SPPage
                creating={true}
                application={application}
                setApplication={setApplication}
                sp={sp}
              />
            </>
          )}
          {pageKey === "microsoft" && application && (
            <MicrosoftEAMPage
              creating={true}
              applicationId={application.ApplicationID}
              application={application}
              setApplication={setApplication}
              vertical={false}
            />
          )}
          {pageKey === "okta" && application && (
            <OktaPage
              creating={true}
              application={application}
              setApplication={setApplication}
              vertical={false}
            />
          )}
          {pageKey === "onelogin" && application && (
            <OneloginPage
              creating={true}
              application={application}
              setApplication={setApplication}
              vertical={false}
            />
          )}
        </>
      )}
    </>
  );
};

export default NewOrEditApplication;
