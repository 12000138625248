import { Device } from "../API/XFA_API";
import { DeviceRisk, getSupported, isDefined } from "./UserUtils";

export type DeviceCheck =
  | "os_uptodate"
  | "os_autoupdate"
  | "diskencryption_active"
  | "authentication_active"
  | "antivirus_enabled"
  | "chrome_uptodate"
  | "firefox_uptodate"
  | "edge_uptodate"
  | "safari_uptodate"
  | "passwordmanager"
  | "biometrics"
  | "nativeClientInstalled";

export function getDeviceCheck(
  device: Device,
  deviceCheck: DeviceCheck,
): DeviceRisk {
  const checkMapping: { [key in DeviceCheck]?: DeviceRisk } = {
    os_uptodate: createVersionCheck(device.os_version, device.os_uptodate),
    os_autoupdate: isDefined(device.os_autoupdate)
      ? device.os_autoupdate === true
        ? DeviceRisk.Safe
        : DeviceRisk.Unsafe
      : DeviceRisk.Unknown,
    diskencryption_active: isDefined(device.diskencryption_active)
      ? device.diskencryption_active === true
        ? DeviceRisk.Safe
        : DeviceRisk.Unsafe
      : DeviceRisk.Unknown,
    authentication_active: isDefined(device.authentication_active)
      ? device.authentication_active === true
        ? DeviceRisk.Safe
        : DeviceRisk.Unsafe
      : DeviceRisk.Unknown,
    biometrics: isDefined(device.biometrics)
      ? device.biometrics !== "none"
        ? DeviceRisk.Safe
        : DeviceRisk.Unsafe
      : DeviceRisk.Unknown,
    antivirus_enabled: isDefined(device.antivirus_enabled)
      ? device.antivirus_enabled === true
        ? DeviceRisk.Safe
        : DeviceRisk.Unsafe
      : DeviceRisk.Unknown,
    chrome_uptodate: createVersionCheck(
      device.chrome_version,
      device.chrome_uptodate,
    ),
    firefox_uptodate: createVersionCheck(
      device.firefox_version,
      device.firefox_uptodate,
    ),
    edge_uptodate: createVersionCheck(
      device.edge_version,
      device.edge_uptodate,
    ),
    safari_uptodate: createVersionCheck(
      device.safari_version,
      device.safari_uptodate,
    ),
    nativeClientInstalled: isDefined(device.nativeclient_installed)
      ? device.nativeclient_installed === true
        ? DeviceRisk.Safe
        : DeviceRisk.Unsafe
      : DeviceRisk.Unknown,
    passwordmanager: isDefined(device.passwordmanager)
      ? Object.values(device.passwordmanager!).some(
          (enabled) => enabled === true,
        )
        ? DeviceRisk.Safe
        : DeviceRisk.Unsafe
      : DeviceRisk.Unknown,
  };

  return checkMapping[deviceCheck] ?? DeviceRisk.Unknown;
}

function createVersionCheck(
  version: string | undefined,
  uptodate: boolean | undefined,
): DeviceRisk {
  if (!isDefined(version)) {
    return DeviceRisk.Unknown;
  }
  return uptodate === true ? DeviceRisk.Safe : DeviceRisk.Unsafe;
}
