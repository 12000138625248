// utils.tsx

/**
 * Adjusts the transparency of an RGBA color string.
 *
 * @param color - The RGBA color string.
 * @param transparency - The desired transparency value.
 * @returns The adjusted RGBA color string.
 */
export const adjustColorTransparency = (
  color: string,
  transparency: number,
): string => {
  const rgbaPattern = /rgba\((\d+), (\d+), (\d+), (\d+(\.\d+)?)\)/;
  const match = color.match(rgbaPattern);

  if (match) {
    const [_, r, g, b] = match;
    return `rgba(${r}, ${g}, ${b}, ${transparency})`;
  }

  return color;
};

export const isValidEmail = (email: string): boolean => {
  const emailPattern =
    /^(?!\s)(?:(?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*)|"(?:[\x20-\x21\x23-\x5b\x5d-\x7e]|\\[\x20-\x7e])*")@(?!\s)(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\[IPv6:[a-fA-F0-9:]+\]|\[\d{1,3}(?:\.\d{1,3}){3}\])(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?)*$(?!\s)/;
  return emailPattern.test(email);
};
