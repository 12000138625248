import React, { CSSProperties } from "react";
import { TableCell } from "@mui/material";
import DeviceStatusChip from "./DeviceStatusChip";
import { Device, Policies, Shadow } from "../API/XFA_API";
import { getDeviceRisk, getDeviceStatus, getDeviceType } from "./UserUtils";

const DeviceStatusCell = ({
  user,
  activeDevicesUser,
  onClick,
  style,
  policy,
}: {
  user: Shadow;
  activeDevicesUser: (email: string) => Device[];
  onClick?: () => void;
  style: CSSProperties;
  policy?: Policies | undefined;
}) => {
  const activeDevices = activeDevicesUser(user.email);

  return (
    <TableCell component="th" scope="row" onClick={onClick} style={style}>
      {activeDevices.length > 0 && (
        <>
          {activeDevices.map((device: Device) => (
            <DeviceStatusChip
              key={device.device_id}
              status={getDeviceStatus(device)}
              risk={getDeviceRisk(device, policy)}
              label={
                device.computer_name?.name ||
                device.model ||
                getDeviceType(device)
              }
            />
          ))}
        </>
      )}
    </TableCell>
  );
};

export default DeviceStatusCell;
