import React from "react";
import { DottedCircleIcon, DottedCircleIconColored } from "./DottedCircleIcon";
import UnsafeIcon from "../../icons/UnsafeIcon.svg";
import SafeIcon from "../../icons/SafeIcon.svg";
import WarningIcon from "../../icons/WarningIcon.svg";
import UnsupportedIcon from "../../icons/UnsupportedIcon.svg";
import { Diversity2Rounded } from "@mui/icons-material";

export const RiskIcons = {
  safe: (
    <div
      style={{
        marginLeft: 5,
        marginRight: -6,
      }}
    >
      <img src={SafeIcon} style={{ height: "14px", width: "14px" }} />
    </div>
  ),
  skipped: (
    <div
      style={{
        marginLeft: 5,
        marginRight: -6,
      }}
    >
      <img src={WarningIcon} style={{ height: "14px", width: "14px" }} />
    </div>
  ),
  unsafe: (
    <div
      style={{
        marginLeft: 5,
        marginRight: -6,
      }}
    >
      <img src={UnsafeIcon} style={{ height: "14px", width: "14px" }} />
    </div>
  ),
  unsupported: (
    <div
      style={{
        marginLeft: 5,
        marginRight: -6,
      }}
    >
      <img src={UnsupportedIcon} style={{ height: "14px", width: "14px" }} />
    </div>
  ),
  unknown: (
    <div
      style={{
        marginLeft: 5,
        marginRight: -6,
      }}
    >
      <DottedCircleIcon />
    </div>
  ),
  unknownColored: (
    <div
      style={{
        marginLeft: 5,
        marginRight: -6,
      }}
    >
      <DottedCircleIconColored />
    </div>
  ),
};

export const SmallRiskIcons = {
  safe: (
    <div>
      <img src={SafeIcon} style={{ height: "14px", width: "14px" }} />
    </div>
  ),
  skipped: (
    <div>
      <img src={WarningIcon} style={{ height: "14px", width: "14px" }} />
    </div>
  ),
  unsafe: (
    <div>
      <img src={UnsafeIcon} style={{ height: "14px", width: "14px" }} />
    </div>
  ),
  unsupported: (
    <div>
      <img src={UnsupportedIcon} style={{ height: "14px", width: "14px" }} />
    </div>
  ),
  unknown: (
    <div>
      <DottedCircleIcon />
    </div>
  ),
  unknownColored: (
    <div>
      <DottedCircleIconColored />
    </div>
  ),
};
